import OrganisationAdmin from 'eaternity-core/build/modules/admin/components/OrganisationAdmin'
import RestaurantAdmin from 'eaternity-core/build/modules/admin/components/RestaurantAdmin'
import UserAdmin from 'eaternity-core/build/modules/admin/components/UserAdmin'
import NotFound from 'eaternity-core/build/modules/core/components/NotFound'
import UnlicensedContainer from 'eaternity-core/build/modules/core/containers/UnlicensedContainer'
import LoginContainer from 'eaternity-core/build/modules/login/containers/LoginContainer'
import RegisterContainer from 'eaternity-core/build/modules/login/containers/RegisterContainer'
import VerifyContainer from 'eaternity-core/build/modules/login/containers/VerifyContainer'
import RecipeEditContainer from 'eaternity-core/build/modules/recipeEditView/containers/RecipeEditContainer'
import RecipeOrProductContainer from 'eaternity-core/build/modules/recipeOrProduct/containers/RecipeOrProductContainer'
import SearchViewContainer from 'eaternity-core/build/modules/search/containers/SearchViewContainer'
import {restaurantLicenseCheck} from 'eaternity-core/build/utils/dateUtils'
import PropTypes from 'prop-types'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

const Routes = ({
  isAuthenticated,
  isBusinessPartnerAdmin,
  selectedRestaurant,
  webEntryDomain
}) => {
  const isLicensed =
    selectedRestaurant !== undefined &&
    restaurantLicenseCheck(webEntryDomain, selectedRestaurant.licenses)

  return (
    <Switch>
      {!selectedRestaurant && null}

      <Route exact path="/login" render={() => <LoginContainer />} />
      <Route exact path="/register" render={() => <RegisterContainer />} />
      <Route exact path="/verify/:token" render={() => <VerifyContainer />} />

      {!isAuthenticated && <Route render={() => <Redirect to="/login" />} />}
      {!isLicensed && <Route render={() => <UnlicensedContainer />} />}

      <Route exact path="/" render={() => <Redirect to="/search" />} />
      <Route exact path="/search" render={() => <SearchViewContainer />} />
      <Route exact path="/select" render={() => <RecipeOrProductContainer />} />
      <Route exact path="/recipes" render={() => <RecipeEditContainer />} />
      <Route exact path="/restaurants" render={() => <RestaurantAdmin />} />
      <Route exact path="/businesses" render={() => <OrganisationAdmin />} />
      <Route
        exact
        path="/users"
        render={() => {
          return isBusinessPartnerAdmin ? <UserAdmin /> : <NotFound />
        }}
      />

      <Route render={() => <NotFound />} />
    </Switch>
  )
}

Routes.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  selectedRestaurant: PropTypes.object,
  webEntryDomain: PropTypes.string.isRequired
}

export default Routes
