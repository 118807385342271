import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {createApi} from 'eaternity-core/build/api/api'
import {createBrowserHistory} from 'history'
import {createEpicMiddleware} from 'redux-observable'
import {of, fromEvent} from 'rxjs'
import {rootEpic} from 'eaternity-core/build/redux/rootEpic'
import Raven from 'raven-js'
import createRavenMiddleware from 'raven-for-redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {configuration} from '../configuration'
import createRootReducer from './rootReducer'

const {NODE_ENV, REACT_APP_VERSION, REACT_APP_HOST_HOSTNAME} = process.env

/*
 router middleware
*/
const history = createBrowserHistory()
const connectedRouterMiddleware = routerMiddleware(history)

/*
epic middleware
*/
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    api: createApi(configuration.webEntryDomain),
    fromEvent,
    of
  }
})

/*
  set up redux middleware for different environments
*/
const composeEnhancers = composeWithDevTools({name: 'klimateller app'})
const _devMiddleware = [epicMiddleware, connectedRouterMiddleware]

let middleware = composeEnhancers(applyMiddleware(..._devMiddleware))
if (NODE_ENV === 'production') {
  /*
    raven (sentry) logger middleware
  */
  Raven.config('https://09b8fe156fa847cf9f4cb20d6e3e03cf@sentry.io/1308756', {
    autoBreadcrumbs: false,
    release: `${configuration.webEntryDomain}-app@${REACT_APP_VERSION}`,
    environment: REACT_APP_HOST_HOSTNAME
  }).install()

  const ravenMiddleWare = createRavenMiddleware(Raven)
  middleware = applyMiddleware(..._devMiddleware, ravenMiddleWare)
}

const store = createStore(createRootReducer(history), middleware)

epicMiddleware.run(rootEpic)

export {history, store}
