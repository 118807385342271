/*
 this file is used to configure differences like branding, feature set etc..between the 2 client side apps
*/

export const configuration = {
  visibleIndicators: ['co2'],
  visibleIndicatorsOnComponentLevel: ['co2'],
  visibleNavIcons: [],
  webEntryDomain: 'klimateller'
}
