import useBowserState from 'eaternity-core/build/hooks/useBowserState'
import NotSupported from 'eaternity-core/build/modules/core/components/NotSupported'
import LoadingIndicatorModalContainer from 'eaternity-core/build/modules/modal/containers/LoadingIndicatorModalContainer'
import PropTypes from 'prop-types'
import React, {lazy, Suspense, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import ReactResizeDetector from 'react-resize-detector'
import {Route} from 'react-router-dom'
import RoutesContainer from '../../routing/containers/RoutesContainer'

const AddOrEditOrganisationModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditOrganisationModalContainer'
  )
)

const AddOrEditRestaurantModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditRestaurantModalContainer'
  )
)

const AddOrEditUserModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AddOrEditUserModalContainer'
  )
)

const AuthTokenExpiredModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/AuthTokenExpiredModalContainer'
  )
)

const BuyLicenseModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/BuyLicenseModalContainer'
  )
)

const ConfigureProductModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/productEditView/containers/ConfigureProductModalContainer'
  )
)

const CompositeDeleteModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/CompositeDeleteModalContainer'
  )
)

const LinkExpiredModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LinkExpiredModalContainer'
  )
)

const LoginAgainModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LoginAgainModalContainer'
  )
)

const LogoutWarningModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/LogoutWarningModalContainer'
  )
)

const OfflineModal = lazy(() =>
  import('eaternity-core/build/modules/modal/components/OfflineModal')
)

const ProductIconSprite = lazy(() =>
  import('eaternity-core/build/modules/icons/components/ProductIconSprite')
)

const ReloadModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/ReloadModalContainer')
)

const ResizeModalContainer = lazy(() =>
  import('eaternity-core/build/modules/modal/containers/ResizeModalContainer')
)

const SearchModalContainer = lazy(() =>
  import('eaternity-core/build/modules/search/containers/SearchModalContainer')
)

const RemoveBusinessModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveBusinessModalContainer'
  )
)

const RemoveRestaurantModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveRestaurantModalContainer'
  )
)

const RemoveUserModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RemoveUserModalContainer'
  )
)

const RestaurantSettingsModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/RestaurantSettingsModalContainer'
  )
)

const UserSettingsModalContainer = lazy(() =>
  import(
    'eaternity-core/build/modules/modal/containers/UserSettingsModalContainer'
  )
)

const App = ({
  checkServerStatus,
  daysUntilLicenseExpiry,
  setResizing,
  showBuyLicenseModal,
  startEnrichStream,
  startShowBuyLicenseModalInterval,
  startSyncStream,
  stopEnrichStream,
  stopShowBuyLicenseModalInterval,
  stopSyncStream
}) => {
  const {isValidBrowser} = useBowserState()
  const i18n = useTranslation()[1]

  useEffect(() => {
    if (isValidBrowser) {
      // the following setTimeout makes the user experience much nicer when the
      // user clicks on a login or register link and thus enters the app via the
      // verify route. the verify route redirects to "/" after 4s...
      let timeoutId
      if (daysUntilLicenseExpiry <= 31) {
        timeoutId = window.setTimeout(showBuyLicenseModal, 10 * 1000)
      }
      startShowBuyLicenseModalInterval()

      if (!window.Cypress) {
        startEnrichStream()
        startSyncStream()
      }

      checkServerStatus()

      i18n.changeLanguage('de')

      return () => {
        stopShowBuyLicenseModalInterval()

        if (!window.Cypress) {
          stopEnrichStream()
          stopSyncStream()
        }

        if (daysUntilLicenseExpiry <= 31) {
          window.clearTimeout(timeoutId)
        }
      }
    }
  }, [
    checkServerStatus,
    daysUntilLicenseExpiry,
    i18n,
    isValidBrowser,
    showBuyLicenseModal,
    startEnrichStream,
    startShowBuyLicenseModalInterval,
    startSyncStream,
    stopEnrichStream,
    stopShowBuyLicenseModalInterval,
    stopSyncStream
  ])

  return !isValidBrowser ? (
    <Route render={() => <NotSupported />} />
  ) : (
    <>
      <ReactResizeDetector handleWidth onResize={() => setResizing(true)} />
      <RoutesContainer />
      <Suspense fallback={<LoadingIndicatorModalContainer isOpen />}>
        <ProductIconSprite />
        <AddOrEditOrganisationModalContainer />
        <AddOrEditRestaurantModalContainer />
        <AddOrEditUserModalContainer />
        <AuthTokenExpiredModalContainer />
        <CompositeDeleteModalContainer />
        <ConfigureProductModalContainer />
        <LinkExpiredModalContainer />
        <LoginAgainModalContainer />
        <LogoutWarningModalContainer />
        <ReloadModalContainer />
        <RemoveBusinessModalContainer />
        <RemoveRestaurantModalContainer />
        <RemoveUserModalContainer />
        <ResizeModalContainer />
        <SearchModalContainer />
        <RestaurantSettingsModalContainer />
        <UserSettingsModalContainer />
        <BuyLicenseModalContainer />
        <OfflineModal />
      </Suspense>
    </>
  )
}

App.propTypes = {
  checkServerStatus: PropTypes.func.isRequired,
  daysUntilLicenseExpiry: PropTypes.number,
  setResizing: PropTypes.func.isRequired,
  showBuyLicenseModal: PropTypes.func.isRequired,
  startEnrichStream: PropTypes.func.isRequired,
  startShowBuyLicenseModalInterval: PropTypes.func.isRequired,
  startSyncStream: PropTypes.func.isRequired,
  stopEnrichStream: PropTypes.func.isRequired,
  stopShowBuyLicenseModalInterval: PropTypes.func.isRequired,
  stopSyncStream: PropTypes.func.isRequired
}

export default App
